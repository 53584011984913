import { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import { initI18n } from '@bluecodecom/i18n';

import { configService } from './services';

initI18n({ en: {}, de: {}, it: {} }, undefined, true);

const App = lazy(() => import('./app'));

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://terms-api.${configService.domain}/client/graphql`,
});

const Main = () => (
  <ThemeProvider observe>
    <LoaderProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route
              path=":serviceName/:kind/:version/:language"
              element={<App />}
            />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </LoaderProvider>
  </ThemeProvider>
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Main />,
);
